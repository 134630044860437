import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { theme } from '@styles';
import FooterSection from '@pageSections/common/footer';
import { IPrismicPrivacy, PageContext } from '@appTypes';

import CallToActionSection from '@pageSections/common/callToAction';
import { isBrowser } from '@utils';

const { colors, breakpoints } = theme;

const Privacy: React.FC<PageProps<IPrismicPrivacy, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const [mobileAppSelected, setMobileAppSelected] = useState('');
  const data = prismicData!.edges[0].node.data;

  useEffect(() => {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const mobileApp = urlParams.get('mobileApp');
      if (mobileApp) {
        setMobileAppSelected(mobileApp);
      }
    }
  });

  const { locale } = pageContext;

  const { meta_title, meta_description, meta_image, meta_url } = data;

  return (
    <>
      <Container>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />
        {!mobileAppSelected && (
          <>
            <TitleContainer>Privacy Policy</TitleContainer>
          </>
        )}
        <PrismicLegalContent
          dangerouslySetInnerHTML={{
            __html: data.privacytextnew.html,
          }}
        />
      </Container>
      {!mobileAppSelected && <CallToActionSection prismicCtaData={prismicCtaData} />}
      {!mobileAppSelected && <FooterSection prismicFooterData={prismicFooterData} />}
    </>
  );
};

export default Privacy;

const TitleContainer = styled.h1`
  font-family: 'Poppins';
  color: ${colors.outerSpace};
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.2px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 60px;
`;

const PrismicLegalContent = styled.div`
  font-size: 14px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 700px;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  margin-top: 34px;
  margin-bottom: 26px;
  padding: 0 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
    margin-bottom: 54px;
    padding: 0 24px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
    margin-bottom: 110px;
  }
`;

export const pageQuery = graphql`
  query PrivacyPageQuery($locale: String!) {
    prismicData: allPrismicPrivacyPolicy(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_description
            meta_image {
              url
            }
            meta_url
            meta_keywords
            privacytextnew {
              html
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
